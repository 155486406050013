<template>
    <div>
        <!-- begin::Entry -->
        <div class="d-flex flex-column flex-row-fluid">
            <!--begin::Container-->
            <div class="container-fluid py-8">
                <!--begin::Education-->
                <div class="d-flex flex-row">
                    <UserNav activePage="dashboard" activeTab="1"></UserNav>
                    <!--begin::Content-->
                    <div class="flex-row-fluid ml-lg-8">
                        <div class="row">
                            <div class="col-xxl-6" v-if="coachFeed.length > 0 && coachFeed[0].online == true">
                                <FeedBox :coachFeed=coachFeed />
                            </div>
                            <div class="col-xxl-6" v-else>
                                <div class="card card-custom gutter-b h300">
                                    <!--begin::Body-->
                                    <div class="card-body pt-0">
                                        <!--begin::Scroll-->
                                        <div>
                                            <!--begin::Messages-->
                                            <div class="messages">
                                                <!--begin::Message In-->
                                                <h5>
                                                    <div class="text-center center">
                                                        <p>
                                                            This session is offline!
                                                        </p>
                                                        <p class="pt-5">
                                                            <i class="fa fa-video-slash icon-2x"></i>
                                                        </p>
                                                    </div>
                                                </h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                        </div>
                        <div class="col-xxl-6">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div></template>

<script>
import FeedBox from "@/view/pages/feed/Feed.vue";
import UserNav from "@/view/layout/header/UserNav.vue";
import { mapGetters } from "vuex";
import Swal from "sweetalert2";
import * as fb from '../../../api/firebase.js';
import formateDateMixin from "@/mixins/formatDate"

export default {
    name: 'feeddetailother',
    components: {
        UserNav,
        FeedBox
    },
    mixins: [formateDateMixin],
    data() {
        return {
            coachFeed: [],
            detailForm: {
                sessionDescription: "",
                maxChars: 200,
            },
            sessionTitle: "",
            currentUser: []
        }
    },
    async mounted() {
        this.$store.dispatch("getCurrentCoachDetail")
        this.$store.dispatch("getFeedById", {
            feedid: this.$route.params.feedId
        })
        let user = await fb.firebase.getCurrentUser()
        fb.usersCollection.where("user_id", "==", user.uid).onSnapshot((snapshot) => {
            if (snapshot.docs.length > 0) {
                snapshot.docs.map((doc) => {
                    let user = doc.data()
                    this.currentUser = user
                })
            }
        }, (error) => { })

    },
    computed: {
        ...mapGetters(["individualFeed", "programCoachDetail"])
    },
    watch: {
        individualFeed(newValue) {
            this.coachFeed = newValue
        },
        programSessionDetail(newValue, oldValue) {
            if (newValue && newValue.title !== "" && newValue.title !== null) {
                this.sessionTitle = newValue.title;
            } else {
                this.sessionTitle = "No Sessions Yet";
            }
        },
    },
}
</script>

<style scoped>
body.modal-open {
    overflow: visible;
}

.h200 {
    height: 200px;
    overflow: hidden;
}

.h300 {
    height: 300px;
    overflow: scroll;
}

.fs-125 {
    font-size: 1.25rem;
}

::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 5px;
}

::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: rgba(0, 0, 0, .5);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}

.center {
    margin: 0;
    position: absolute;
    top: 60%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}
</style>