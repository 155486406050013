import { render, staticRenderFns } from "./FeedDetailOther.vue?vue&type=template&id=66881c53&scoped=true&"
import script from "./FeedDetailOther.vue?vue&type=script&lang=js&"
export * from "./FeedDetailOther.vue?vue&type=script&lang=js&"
import style0 from "./FeedDetailOther.vue?vue&type=style&index=0&id=66881c53&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "66881c53",
  null
  
)

export default component.exports